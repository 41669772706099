import React from "react";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <a
        href="https://www.linkedin.com/in/casper-ravn-petersen-21b32780/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="textContainer">
          <h1 className="name">Casper Ravn Petersen</h1>
          <i className="title">app & web developer</i>
        </div>
      </a>
    </div>
  );
};

export default App;
